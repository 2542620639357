.App {
  text-align: center;
	box-sizing: border-box;
	color: white;
	text-transform: full-width;
  overflow-x: hidden;
  text-align: center;
  font-size: 14pt;
}

body {
  background-color: #000000ea;  
 
  /* transition the background-color over 1s with a linear animation */
  /*transition: background-color 1s linear;*/
 
}

h1 {
    font-weight: lighter;
    padding: 0;
    margin: 0;
    bottom: -50px;
    
}

p {
    bottom: -150px;
}

a {
    color: #ffffff;
    font-size: 28px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 2vh 3vw 2vh 3vw;
    bottom: -400px;
    column-span: all;
}

.link_in_programmer {
  font-size: 14pt;
  padding-left: 0%;
}

  .home_page_list{
    list-style: none;
  }

